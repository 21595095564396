import React, { createContext, useContext, useState } from 'react'

import { IAlertContext, IAlert } from '../models/alert-context'

import { ContainerAlert, AlertContent } from './alertStyle'
import { ButtonGreen } from '../styles/global'
import { useTheme } from 'styled-components'

const AlertContext = createContext({} as IAlertContext)

const InitialAlertConfig: IAlert = {
    open: false,
    text: '',
    type: 'informative'
}

const ThemeProvider: React.FC = ({ children }) => {
    const { colors } = useTheme();
    const [AlertConfig, setAlertConfig] = useState<IAlert>(InitialAlertConfig)

    const alert = (props: IAlert): any => {
        if (props.text) {
            setAlertConfig({ ...props, open: true })

            if (Boolean(props.timeClose)) {
                setTimeout(() => {
                    setAlertConfig({ ...props, open: false })
                }, props.timeClose)
            }
        }
    }

    return (
        <AlertContext.Provider value={{ alert }}>
            {AlertConfig.open && (
                <ContainerAlert open={AlertConfig.open}>
                    <AlertContent
                        width={AlertConfig.width}
                        height={AlertConfig.height}
                        type={AlertConfig.type}
                    >
                        {Boolean(AlertConfig.icon) && (
                            <img
                                src={AlertConfig.icon}
                                style={{
                                    width: 90,
                                    height: 90,
                                    marginBottom: 20
                                }}
                                alt="Icon"
                            />
                        )}
                        <div
                            style={AlertConfig.textStyle}
                            dangerouslySetInnerHTML={{
                                __html: AlertConfig.text
                            }}
                        />
                        {!AlertConfig.buttonDisabled && (
                            <>
                                {AlertConfig.type === 'question' && (
                                    <>
                                        <ButtonGreen
                                            style={{ marginTop: 30 }}
                                            fontSize="18px"
                                            height="40"
                                            background={
                                                AlertConfig.buttonOkColor ??
                                                colors.Primary
                                            }
                                            onClick={() =>
                                                typeof AlertConfig.buttonOkSubmit !==
                                                'undefined'
                                                    ? AlertConfig.buttonOkSubmit()
                                                    : ''
                                            }
                                        >
                                            {AlertConfig.buttonOkText}
                                        </ButtonGreen>
                                    </>
                                )}
                                <ButtonGreen
                                    style={{ marginTop: 10 }}
                                    fontSize="18px"
                                    height="40"
                                    background={
                                        AlertConfig.buttonCancelColor ??
                                        colors.PrimaryLight2
                                    }
                                    onClick={() =>
                                        setAlertConfig({
                                            ...AlertConfig,
                                            open: false
                                        })
                                    }
                                >
                                    {AlertConfig.buttonCancelText ?? 'CANCELAR'}
                                </ButtonGreen>
                            </>
                        )}
                    </AlertContent>
                </ContainerAlert>
            )}
            {children}
        </AlertContext.Provider>
    )
}

export function useAlert() {
    const context = useContext(AlertContext)
    const { alert } = context
    return { alert }
}

export default ThemeProvider
