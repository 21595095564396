import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import PublicRoutes from './public-routes'
import PrivateRoutes from './private-routes'

import { isAuthenticated } from '../store/actions/user'

import { AppState } from '../store'
import { IUserState } from '../containers/payment-link/models/user'

const Routes: React.FC = () => {
    const { token }: IUserState = useSelector((state: AppState) => state.User)
    const [loadingAuthenticated] = useState<Promise<boolean>>(() =>
        isAuthenticated().then((res: boolean) => {
            return res
        })
    )

    return typeof loadingAuthenticated !== 'undefined' && !!token ? (
        <PrivateRoutes loadingAuthenticated={loadingAuthenticated} />
    ) : (
        <PublicRoutes />
    )
}

export default Routes
