import { createStore, combineReducers } from 'redux'
import { IAction } from '../models/action-type'

import PagseguroCheckoutReducer from './reducers/pagseguro'
import MercadopagoCheckoutReducer from './reducers/mercadopago'
import AdiqCheckoutReducer from './reducers/adiq'
import LinksReducer from './reducers/links'
import UserReducer from './reducers/user'

const reducers = combineReducers({
  CheckoutPagseguro: PagseguroCheckoutReducer,
  CheckoutMercadopago: MercadopagoCheckoutReducer,
  CheckoutAdiq: AdiqCheckoutReducer,
  Links: LinksReducer,
  User: UserReducer,
});

const rootReducer = (state: any, action: IAction) => {
  return reducers(state, action);
};

const store = createStore(rootReducer);

export type AppState = ReturnType<typeof rootReducer>

export default store;