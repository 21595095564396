export default {
    API: {
        UNIMED: {
            token: '',
            url: process.env.REACT_APP_API_URL,
        }
    },
    messages: {
        apiError: 'Desculpe, houve um problema ao se comunicar com o servidor!'
    }
}