import React, { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { doLogout } from '../store/actions/user';

import FaviconUnimed from '../assets/images/faviconUnimed.png';

const LinkList = lazy(() => import('../containers/payment-link/pages/links'))
const CheckoutPayment = lazy(() => import('../containers/payment-link/pages/checkout'))

const PrivateRoutes: React.FC<{ loadingAuthenticated: Promise<boolean> | undefined }> = ({ loadingAuthenticated }) => {
    const mainTitle = 'UNIMED PAY';
    const faviconLink: HTMLLinkElement | null = document.getElementById("favicon") as HTMLLinkElement | null;
        

    useEffect(() => {
        document.title = `${mainTitle} | Link de Pagamento`
        if(faviconLink) {
            faviconLink.href = FaviconUnimed;
        }
    }, [mainTitle, faviconLink]);
    
    if(typeof loadingAuthenticated !== 'undefined') {
        
        return (
            <BrowserRouter>
                <Suspense fallback={<></>}>
                    <Switch>
                        <Route exact path="/links" component={LinkList} />
                        <Route exact path="/logout" render={async () => {
                            await doLogout()
                            window.close()
                            return <></>
                        }} />
                        <Route exact path="/login" render={async ({ match: { url } }) => (
                            <Switch>
                                {await loadingAuthenticated && (
                                    <Redirect to={{ pathname: '/links' }} />
                                )}
                                <Route path={`${url}`} exact render={() => {
                                        const redirectUrl = process.env.REACT_APP_MY_BUSSINES_URL ?? '/';
                                        if (window.top) window.top.location.href = redirectUrl;
                                        return <></>;
                                    }}
                                />
                            </Switch>
                            )}
                        />
                        <Route exact path="/:id" component={CheckoutPayment} />
                        <Redirect to={{ pathname: '/links' }} />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        )
    } else {
        return (
            <></>
        )
    }
}

export default PrivateRoutes
