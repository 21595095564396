interface TypeColors {
  colors: {
    Primary: string,
    PrimaryLight1: string,
    PrimaryLight2: string,
    PrimaryLight3: string,
    PrimaryLight4: string,
    Secundary: string,
    Orange: string,
    OrangeLight: string,
    Purple: string,
    Gray1: string,
    Gray2: string,
    Gray3: string,
    Gray4: string,
    Gray5: string,
    Gray6: string,
    Gray7: string,
    Gray8: string,
    Gray9: string,
    Gray10: string,
    GrayLight1: string,
    GrayLight2: string,
    GrayLight3: string,
    WhiteSmoke1: string,
    WhiteSmoke2: string,
    WhiteSmoke3: string,
    WhiteSmoke4: string,
    White: string,
    Black: string,
    Red: string,
    Success: string,
    Error: string,
  }
}

const ThemeUnimed: TypeColors = {
  colors: {
    Primary: '#00995D',
    PrimaryLight1: '#01995D',
    PrimaryLight2: '#affadd',
    PrimaryLight3: '#D7F5C1',
    PrimaryLight4: '#d7fced',
    Secundary: '#56d59a',
    Orange: '#ffc250',
    OrangeLight: '#ffedb4',
    Purple: '#c3ade6',
    Gray1: '#666666',
    Gray2: '#8d8d8d',
    Gray3: '#888888',
    Gray4: '#999999',
    Gray5: '#a0a0a0',
    Gray6: '#adadad',
    Gray7: '#acacac',
    Gray8: '#c6c6c6',
    Gray9: '#cfcfcf',
    Gray10: '#cccccc',
    GrayLight1: '#d8d8d8',
    GrayLight2: '#dddddd',
    GrayLight3: '#efefef',
    WhiteSmoke1: '#f0f0f0',
    WhiteSmoke2: '#f1f1f1',
    WhiteSmoke3: '#f4f4f4',
    WhiteSmoke4: '#F6F6F6',
    White: '#FFFFFF',
    Black: '#000000',
    Red: '#fa1e1e',
    Success: '#00FF00',
    Error: '#FD5F5F',
  }
}

const themeApp = ThemeUnimed;

export default themeApp ;