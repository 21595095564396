enum type {
    SET_DATA_CHECKOUT_PAGSEGURO = 'SET_DATA_CHECKOUT_PAGSEGURO',
    SET_DATA_CHECKOUT_MERCADOPAGO = 'SET_DATA_CHECKOUT_MERCADOPAGO',
    SET_DATA_CHECKOUT_ADIQ = 'SET_DATA_CHECKOUT_ADIQ',
    SET_LIST_LINKS = 'SET_LIST_LINKS',
    SET_LOADING_LIST_LINKS = 'SET_LOADING_LIST_LINKS',
    SET_LINK = 'SET_LINK',
    SET_USER_TOKEN = 'SET_USER_TOKEN',
    SET_USER_TAXS = 'SET_USER_TAXS',
    SET_LOADING_USER = 'SET_LOADING_USER',
}

export default type;
