import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { doLogout } from '../store/actions/user';

const Login = lazy(() => import('../containers/payment-link/pages/login'))
const CheckoutPayment = lazy(() => import('../containers/payment-link/pages/checkout'))

const PublicRoutes: React.FC = () => {
    return (
        <BrowserRouter>
            <Suspense fallback={<></>}>
                <Switch>
                    <Route exact path="/login" render={() => {
                            const redirectUrl = process.env.REACT_APP_MY_BUSSINES_URL ?? '/';
                            if (window.top) window.top.location.href = redirectUrl;
                            return <></>;
                        }}
                    />
                    <Route exact path="/logout" render={async () => {
                        await doLogout()
                        window.close()
                        return <></>
                    }} />
                    <Route exact path="/login/:token" component={Login} />
                    <Route exact path="/:id" component={CheckoutPayment} />
                    <Redirect to="/login" />
                </Switch>
            </Suspense>
        </BrowserRouter>
    )
}

export default PublicRoutes
