import axios from 'axios'
import { decodeToken } from 'react-jwt';

import { ILoginResponse } from '../containers/payment-link/models/login'
import { IUserDataToken } from '../containers/payment-link/models/user';
import { IResponseAPI, IAPIReturnCall } from '../models/api'

import { renewToken, doLogout } from '../store/actions/user'

import { APP_TOKEN } from '../utils/constants'

export const apiExternal = axios.create({
    headers: { 'Content-Type': 'application/json' }
})

export const api = axios.create({
    headers: { 'Content-Type': 'application/json' }
})

api.interceptors.request.use(
    function (config) {
        if (APP_TOKEN) {
            const urlsTokenFixed = [
                `link`, // Page of Checkout
                `${process.env.REACT_APP_API_URL}/v1/user/login`,
                `${process.env.REACT_APP_API_URL}/v1/link/ps/session`,
                `${process.env.REACT_APP_API_URL}/v1/link/ps/checkout`,
                `${process.env.REACT_APP_API_URL}/v1/link/mp/checkout`,
                `${process.env.REACT_APP_API_URL}/v1/link/ad/checkout`,
                `${process.env.REACT_APP_API_URL}/v1/link/ad/tokenization`,
                `${process.env.REACT_APP_API_URL}/v1/link/ad/validation`,
            ]
            
            if (config.url) {
                var configUrlLength = config.url.split("/").length
                var pos1 = configUrlLength - 2
                var pos2 = configUrlLength - 3
                var linkUrl = config.url.split("/")

                if(urlsTokenFixed.includes(config.url) || ((urlsTokenFixed.includes(linkUrl[pos1]) || urlsTokenFixed.includes(linkUrl[pos2])) && config.method !== "delete")) {
                    config.headers.Authorization = process.env.REACT_APP_API_KEY_FINANCIAL
                } else {
                    const storageName = localStorage.getItem(APP_TOKEN)
                    if (storageName) {
                        const storage: ILoginResponse = JSON.parse(storageName)
                        if (storage.accessToken) config.headers.Authorization = storage.accessToken;
                    }
                }
            }
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        if (error.response.status === 401 && error.config.url !== `${process.env.REACT_APP_API_URL}/v1/auth/refresh` && (error.config.headers.Authorization && error.config.headers.Authorization !== process.env.REACT_APP_API_KEY_FINANCIAL)) {
            if (APP_TOKEN) {
                const storageName = localStorage.getItem(APP_TOKEN)
                if (storageName) {
                    const storage: ILoginResponse = JSON.parse(storageName)
                    const res: IResponseAPI<ILoginResponse | null> = await renewToken(storage)
                    if (res.status === 'success') {
                        const config = error.config;
                        config.headers.Authorization = res.data?.accessToken
                        if (APP_TOKEN && res.data) {
                            const userData: IUserDataToken = decodeToken(res.data?.accessToken)
                            const newUserData: IAPIReturnCall<ILoginResponse> | IUserDataToken = {
                                ...res.data,
                                ...userData
                            }
                            localStorage.setItem(APP_TOKEN, JSON.stringify(newUserData))
                        }
                        return await axios.request(config)
                    }
                }
            }
            doLogout()
            window.top.location.href = `${process.env.REACT_APP_BASE_URL}/login`
        }
        return Promise.reject(error);
    }
)