import type from '../actions/types';
import { IAction } from '../../models/action-type';
import { ICheckoutState, IHolderPagseguro, IAddress, ICardPagseguro } from '../../containers/payment-link/models/checkout';

const INITIAL_STATE: ICheckoutState<IHolderPagseguro | IAddress | ICardPagseguro> = {
  data: {
    birthDate: '',
    cpf: '',
    email: '',
    name: '',
    phone: '',
    city: '',
    complement: '',
    district: '',
    number: '',
    state: '',
    street: '',
    postalCode: '',
    cardToken: '',
    senderHash: '',
    nameCard: '',
    installmentNoInterestQuantity: 0,
    installmentQuantity: '0',
    installmentValue: 0
  }
};

function reducer(state: ICheckoutState<IHolderPagseguro | IAddress | ICardPagseguro> = INITIAL_STATE, action: IAction) {
  switch (action.type) {
    case type.SET_DATA_CHECKOUT_PAGSEGURO:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };
    default:
      return state;
  }
}

export default reducer;
