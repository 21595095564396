import type from '../actions/types';
import { IAction } from '../../models/action-type';
import { IUserState} from '../../containers/payment-link/models/user';

const INITIAL_STATE: IUserState = {
    isLoading: false,
    token: '',
    taxs: []
};

function reducer(state: IUserState = INITIAL_STATE, action: IAction) {
  switch (action.type) {
    case type.SET_LOADING_USER:
      return {
        ...state,
        isLoading: action.payload
      };
    case type.SET_USER_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case type.SET_USER_TAXS:
      return {
        ...state,
        taxs: action.payload
      };  
    default:
      return state;
  }
}

export default reducer;