import React from 'react'
import { Provider } from 'react-redux'

import { ThemeProvider } from 'styled-components'
import themeApp from './styles/themes';

import AlertContext from './hooks/alert'
import { GlobalStyle } from './styles/global'

import store from './store'
import Routes from './routes'

// public

const App: React.FC = () => {
    return (
        <ThemeProvider theme={themeApp}>
            <Provider store={store}>
                <AlertContext>
                    <GlobalStyle />
                    <Routes />
                </AlertContext>
            </Provider>
        </ThemeProvider>
    )
}

export default App
