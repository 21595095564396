import type from '../actions/types';
import { IAction } from '../../models/action-type';
import { IAddress, ICheckoutState, IHolderMercadopago, IRequestCheckoutMercadopago } from '../../containers/payment-link/models/checkout';

const INITIAL_STATE: ICheckoutState<IHolderMercadopago | IAddress | IRequestCheckoutMercadopago> = {
  data: {
    docType : '',
    docNumber: '',
    email: '',
    phone: '',
    city: '',
    complement: '',
    district: '',
    number: '',
    state: '',
    street: '',
    postalCode: '',
  }
};

function reducer(state: ICheckoutState<IHolderMercadopago | IAddress | IRequestCheckoutMercadopago> = INITIAL_STATE, action: IAction) {
  switch (action.type) {
    case type.SET_DATA_CHECKOUT_MERCADOPAGO:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };
    default:
      return state;
  }
}

export default reducer;
