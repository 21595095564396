import styled, { keyframes } from 'styled-components'
import { fadeIn, fadeOut } from 'react-animations'

interface IAlertContent {
    width?: string
    height?: string
    type: 'informative' | 'question'
}

interface IContainerAlert {
    open: boolean
}

export const ContainerAlert = styled.div<IContainerAlert>`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.White}70;
    z-index: 999;
    animation: 0.5s ${props => props.open ? keyframes`${fadeIn}` : keyframes`${fadeOut}`} 
`
export const AlertContent = styled.div<IAlertContent>`
    width: ${props => Boolean(props.width) ? props.width : "250px"};
    height: ${props => Boolean(props.height) ? props.height : "250px"};
    ${props => props.type === "informative" ? 
    `background: ${props.theme.colors.Primary};
     color: ${props.theme.colors.White};` 
     : 
    `background: ${props.theme.colors.White};
     color: ${props.theme.colors.Primary};
    `
    }
    box-shadow: 0px 0px 10px ${(props) => props.theme.colors.Black}40;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-size: 14px;
    z-index: 9999;
    padding: 13px;
`