import styled, { createGlobalStyle } from 'styled-components'

interface IBackgroundBanner {
    height: string
}

interface ITextDescription {
    fontSize: string
}

interface IButtonGreen {
    fontSize: string,
    height?: string,
    disabled?: boolean,
    background?: string
}

interface IBoxCardIcon {
    selected: boolean
}

interface IContainerCardIcon {
    error: boolean
}

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0 auto;
        position: relative;
        min-height: 100vh;
    }
    @media ${(`(max-width: 480px)`)} {
        input[type='text'],textarea {
            font-size:1em;
        }
    }
    .rccs__card--unknown > div {
      background: linear-gradient(0deg, ${props => props.theme.colors.GrayLight3}, ${props => props.theme.colors.GrayLight3});
      box-shadow: none; 
    }
    .rccs__expiry__valid {
      font-size: 10px;
      margin-bottom: 5px;
      font-family: 'Roboto', sans-seri;
      color: ${(props) => props.theme.colors.Gray7};
    }
    .rccs__name, .rccs__number, .rccs__expiry__value {
        color: ${(props) => props.theme.colors.Gray7};;
        font-family: 'Roboto', sans-seri;
    }
    .rccs__card {
        width: 350px;
    }
    .rccs {
        width: 100%;
    }
`
export const BackgroundBanner = styled.div<IBackgroundBanner>`
    background: ${(props) => props.theme.colors.Primary};;
    position: absolute;
    width: 100%;
    height: ${props => props.height};
    top: 0;
    left: 0;
    z-index: -1;
`
export const TextTitle = styled.p<ITextDescription>`
    color: ${(props) => props.theme.colors.Primary};;
    font-family: 'Roboto', sans-seri;
    font-weight: bold;
    margin: 0px;
    font-size: ${props => props.fontSize}px;
`
export const TextDescription = styled.p<ITextDescription>`
    color: ${(props) => props.theme.colors.Gray6};;
    font-family: 'Arial';
    font-size: ${props => props.fontSize}px;
`
export const ReceiptSuccess = styled.div`
    padding: 5px;
    margin-top: 30px 20px 20px 20px;
    border: 2px dashed ${(props) => props.theme.colors.Gray10};;
    color: ${(props) => props.theme.colors.Gray4};;
    font-family: Roboto;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.OrangeLight};;
`
export const TextItalic = styled.span<ITextDescription>`
    color: ${(props) => props.theme.colors.Primary};;
    font-family: 'Roboto', sans-seri;
    font-style: italic;
    font-size: ${props => props.fontSize}px;
`
export const Container = styled.div`
    margin: 20px;
`
export const GridColumn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
export const BoxFooter = styled.div`
    height: 40px;
    background: ${(props) => props.theme.colors.Primary};;
    border-radius: 40px 40px 0px 0px;
    display: flex;
    align-items: center;
    margin: 0px 20px 0px 20px;
    position: fixed;
    bottom: 0;
    width: calc(100% - 40px);
    /* left: 0px; */
`
export const ButtonGreen = styled.button<IButtonGreen>`
    height: ${props => props.height ?? '55'}px;
    width: 100%;
    background: ${props => props.disabled ? props.theme.colors.Gray10 : Boolean(props.background) ? props.background : props.theme.colors.Secundary};
    border-radius: 50px;
    color: ${(props) => props.theme.colors.White};;
    border:0;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    font-family: 'Roboto';
    font-size: ${props => props.fontSize};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: filter 0.5s;
    &:hover {
        transition: filter 0.5s;
        ${props => !props.disabled ? 'filter:  brightness(80%);' : ''}
    }
    @media ${(`(max-width: 480px)`)} {
        transform: scale(0.9);
    }
`
export const ButtonLight = styled.button<IButtonGreen>`
    height: 45px;
    width: 100%;
    background: ${props => props.disabled ? props.theme.colors.Gray10 : props.theme.colors.PrimaryLight4};
    border-radius: 50px;
    color: ${props => props.disabled ? props.theme.colors.White : props.theme.colors.Primary};
    border:0;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    font-family: 'Roboto';
    font-size: ${props => props.fontSize};
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${(`(max-width: 480px)`)} {
        transform: scale(0.9);
    }
`
export const BoxFooterCheckout = styled.div`
    bottom: 0px;
    height: 80px;
    width: 100%;
    background: ${(props) => props.theme.colors.Primary};;
    position: absolute;
    left: 0;
    display: flex;
    justify-content: center;
    margin-top: 30px;
`
export const Background = styled.div`
    background: ${(props) => props.theme.colors.WhiteSmoke1};;
    height: 100%;
    width: 100%;
    z-index: -1;
    position: fixed;
    left: 0;
`
export const ErrorMsg = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.colors.Error};;
    font-family: 'Roboto';
    font-weight: bold;
`
export const CheckBox = styled.input`
    
`
export const BoxCardIcon = styled.div<IBoxCardIcon>`
    border: 1px solid ${(props) => props.theme.colors.GrayLight1};;
    box-shadow: ${(props) => props.selected ? `2px 3px 5px ${props.theme.colors.Gray10}` : ''};
    border-radius: 10px;
    width: 65px;
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: ${(props) => props.theme.colors.Gray1};;
    font-family: 'Roboto', sans-seri;
    cursor: pointer;
    & p {
        margin: 5px 0px 0px 0px;
    }
`
export const ContainerCardIcon = styled.div<IContainerCardIcon>`
    display: flex;
    column-gap: 10px;
    margin-top: 20px;
    width: fit-content;
    ${(props) => props.error && `
        border: 1px solid red;
        box-shadow: 0px 0px 12px ${props.theme.colors.Red}04;
        border-radius: 20px;
        padding: 10px;
    `}
`