import { decodeToken } from 'react-jwt';
import store from '..'
import config from '../../config'
import { api } from '../../services/api'
import type from './types'
import { ILogin, ILoginResponse } from '../../containers/payment-link/models/login'
import { IResponseAPI, IAPIReturnCall } from '../../models/api'
import { IIpAddress, IResponseWebTaxs, IUserDataToken } from '../../containers/payment-link/models/user';
import { APP_CONFIRMMODAL, APP_TOKEN } from '../../utils/constants'

const { API: { UNIMED } } = config

const setUserToken = (data: string) => ({
    type: type.SET_USER_TOKEN,
    payload: data,
})

const setTaxs = (data: number[]) => ({
    type: type.SET_USER_TAXS,
    payload: data,
})

export const doLoggin = async (dataLogin: ILogin | undefined, token?: string) => {
    try {
        let options = {}
        if (typeof token !== 'undefined') {
            options = {
                headers: {
                    Authorization: token
                }
            }
        }
        const { data }: IAPIReturnCall<ILoginResponse> = await api.post(`${UNIMED.url}/${typeof token === 'undefined' ? 'v1/user/login' : 'v1/user/login/financial'}`, typeof token === 'undefined' ? dataLogin : null, options)
        if (APP_TOKEN) {
            const userData: IUserDataToken = decodeToken(data.accessToken)
            const newUserData: IAPIReturnCall<ILoginResponse> | IUserDataToken = {
                ...data,
                ...userData
            }
            localStorage.setItem(APP_TOKEN, JSON.stringify(newUserData))
        }

        isAuthenticated()
        return {
            status: 'success',
        } as IResponseAPI<null>
    }
    catch (err) {
        if (err.response && (err.response.status >= 400 && err.response.status <= 405)) {
            config.messages.apiError = "Login ou Senha inválidos !"
        }
        return {
            status: 'error',
            message: config.messages.apiError
        } as IResponseAPI<null>
    }
}

export const getConfirmModal = () => {
    try {
        const jsonValue = localStorage.getItem(APP_CONFIRMMODAL || '');
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        return null;
    }
}

export const setConfirmModal = (value: boolean) => {
    try {
        localStorage.setItem(APP_CONFIRMMODAL || '', JSON.stringify(value));
        return true;
    } catch (e) {
        return null;
    }
}


export const UserData = (): IUserDataToken | undefined => {
    if (APP_TOKEN) {
        const dataString = localStorage.getItem(APP_TOKEN)
        if (dataString) {
            return JSON.parse(dataString) as IUserDataToken
        } else {
            return undefined
        }
    }
}

export const renewToken = async (token: ILoginResponse) => {
    try {
        const { data }: IAPIReturnCall<ILoginResponse> = await api.post(`${UNIMED.url}/v1/auth/refresh`, token)
        return {
            status: 'success',
            data
        } as IResponseAPI<ILoginResponse>
    }
    catch (err) {
        return {
            status: 'error',
        } as IResponseAPI<null>
    }
}

export const isAuthenticated = (): Promise<boolean> => {
    return new Promise((resolve) => {
        if (APP_TOKEN) {
            const storageName = localStorage.getItem(APP_TOKEN)
            if (storageName) {
                const storage: ILoginResponse = JSON.parse(storageName)
                if (Boolean(storage.accessToken)) {
                    store.dispatch(setUserToken(storageName))
                    resolve(true)
                }
            }
        }
        resolve(false)
    })
}

export const doLogout = async () => {
    if (APP_TOKEN) {
        isAuthenticated().then(async (res) => {
            if (res) {
                try {
                    await api.post(`${UNIMED.url}/v1/user/logout`)
                }
                catch (err) { }
            }
        })
        localStorage.clear()
        store.dispatch(setUserToken(''))
    }
}

export const getTaxs = async () => {
    try {
        const { data }: IAPIReturnCall<IResponseWebTaxs> = await api.get(`${UNIMED.url}/v1/merchant/taxes`)
        if (Object.keys(data).length < 12) {
            throw {
                messageError: "Erro ao carregar taxas! Não foram encontrado as 12x parcelas"
            }
        }
        store.dispatch(setTaxs(Object.values(data)))
        return {
            status: "success",
        } as IResponseAPI<any>
    }
    catch (err) {
        return {
            status: "error",
            message: err.messageError ?? "Erro ao carregar taxas!"
        } as IResponseAPI<any>
    }
}

export const getIpAddress = async (): Promise<IResponseAPI<IIpAddress | null>> => {
    try {
        const { data }: IAPIReturnCall<IIpAddress> = await api.get('https://api.ipify.org/?format=json')
        return {
            status: "success",
            data
        } as IResponseAPI<IIpAddress>
    }
    catch (err) {
        return {
            status: "error",
            message: err.messageError ?? "Erro ao capturar ip!"
        } as IResponseAPI<null>
    }
}