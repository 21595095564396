import type from '../actions/types';
import { IAction } from '../../models/action-type';
import { ILinksState } from '../../containers/payment-link/models/links';

const INITIAL_STATE: ILinksState = {
  listLinks: undefined,
  link: undefined,
  isLoading: true,
};

function reducer(state: ILinksState = INITIAL_STATE, action: IAction) {
  switch (action.type) {
    case type.SET_LIST_LINKS:
      return {
        ...state,
        listLinks: action.payload
      };
    case type.SET_LOADING_LIST_LINKS:
      return {
        ...state,
        isLoading: action.payload
      };
    case type.SET_LINK:
      return {
        ...state,
        link: action.payload
      };
    default:
      return state;
  }
}

export default reducer;
