import type from '../actions/types';
import { IAction } from '../../models/action-type';
import { ICheckoutState, IRequestCheckoutAdiq } from '../../containers/payment-link/models/checkout';

const INITIAL_STATE: ICheckoutState<IRequestCheckoutAdiq> = {
  data: {}
};

function reducer(state: ICheckoutState<IRequestCheckoutAdiq> = INITIAL_STATE, action: IAction) {
  switch (action.type) {
    case type.SET_DATA_CHECKOUT_ADIQ:
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      };
    default:
      return state;
  }
}

export default reducer;
